import React from 'react';
import PropTypes from 'prop-types';

const PrevArrow = () => <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="17" transform="rotate(-180 17 17)" fill="#FCFCFC"/>
<path d="M15.3391 17L22.3516 24.0125L20.3484 26.0156L11.3327 17L20.3484 7.98429L22.3516 9.98745L15.3391 17Z" />
</svg>;

const NextArrow = () => <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="17" cy="17" r="17" fill="#FCFCFC"/>
  <path d="M18.6609 17L11.6484 9.98754L13.6516 7.98438L22.6673 17L13.6516 26.0157L11.6484 24.0125L18.6609 17Z" />
</svg>;

const EventOptions = ({ category, categories, changeEventSelected, cards }) => {

  const handlePagination = (direction) => {
    const { scrollLeft, scrollWidth, offsetWidth } = cards.current;

    cards.current.scrollTo({
      top: 0,
      left: scrollLeft + direction,
      behavior: 'smooth',
    });
  };
  
  const handleSelect = (option) => {
    changeEventSelected(option);
  }
  
  return <>
    <div className="event__options__pagination">
      <button type="button" className="event__options__pagination__before" onClick={() => handlePagination(-300)}>
        <PrevArrow/>
      </button>
      <button type="button" className="event__options__pagination__next" onClick={() => handlePagination(280)}>
        <NextArrow/>
      </button>
    </div>
    <div className="event__options">
      <ul className="event__options__categories">
        {
          categories.map(option => (
            <li
              key={option}
              onClick={() => handleSelect(option)}
              className={`event__options__categories__category ${option == category ? '--active' : ''}`}
            >{option}</li>
          ))
        }
      </ul>
    </div>
  </>
}

EventOptions.propTypes = {
  category: PropTypes.string.isRequired,
  setCategory: PropTypes.func.isRequired,
}

export default EventOptions;

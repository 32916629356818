import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchResults from './SearchResults';

const Hero = ({ placeholders, icons, images, imageAlt, invisible }) => {
  const [focus, setFocus] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleDefault = () => {
    setSearchQuery('');
  }

  useEffect(async () => {
    if(searchQuery.trim() != '') {
      const suggestionsResult = await getSearchResults(searchQuery);
      setSuggestions(suggestionsResult);
    } else {
      setSuggestions([]);
    }
  }, [searchQuery])

  const handleFocus = () => {
    setFocus(false);
    handleDefault();
  }

  const turnFocus = () => {
    setFocus(true);
    window.scrollY = 0;
  }

  const getSearchResults = async (name) => {
    const response = await fetch(`/search/main?search_name=${name}`);
    const results = await response.json();
    return results;
  }

  return (
    <div className={`hero ${focus ? 'hero--focus' : ''}  ${(!focus && invisible) ? 'hero-invisible' : ''}`}>
      {images && <img src={ images.coverPage } className="hero__image" alt={ imageAlt } />}
      <div className="hero__search">
        <button onClick={handleFocus} className="hero__search__back" />

        <div className="hero__search__wrap">
          <input
            type='search'
            onClick={turnFocus}
            onChange={e => setSearchQuery(e.target.value)}
            className="hero__search__input"
            id="hero-search"
            placeholder={placeholders && placeholders[Math.floor(Math.random() * 5)] || ''}
            value={searchQuery}
          />

          <SearchResults results={suggestions} icons={icons} />
        </div>

        <button type="submit" className="hero__search__icon" />
      </div>

    </div>
  );
};

Hero.propTypes = {
  placeholders: PropTypes.arrayOf(PropTypes.string).isRequired,
  icons: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Hero;

import React, { useState } from 'react';

const Header = ({icons, i18n}) => {
  /* States */
  const [dropdown, setDropdown] = useState(true);
  
  /* Show nav botton */
  const handleDropdown = () => {
    setDropdown(!dropdown);
  }

  const clickOnHero = () => {
    const hero = document.getElementById('hero-search');
    hero.click();

    setTimeout(() => {
      hero.focus();
    }, 50)
  }

  return (
    <>
      <header className="nav">
        <nav className="nav__area">
          <div className="nav__area__left">
            <div className="nav__area__left__dropdown" 
              dangerouslySetInnerHTML={{ __html: icons.dropdown }}
              onClick={handleDropdown}
            ></div>
            <div
              onClick={() => window.location.href = '/'}
              className= "nav__area__left__icon" 
              dangerouslySetInnerHTML={{ __html: icons.logo }}
            >
            </div>
          </div>
          <div className="nav__area__center">
            <button onClick={() => clickOnHero()}>
              {i18n.searchbar && i18n.searchbar[Math.floor(Math.random() * 5)] || ''}
            </button>
          </div>
          <div className="nav__area__right">
            <div
              className="plus"
              dangerouslySetInnerHTML={{ __html: icons.plus }}>
            </div>
            <div 
              dangerouslySetInnerHTML={{__html: `${i18n.session.signup}`}}
            />            
          </div>
        </nav>
      </header>
      {
        dropdown &&
          <div className="nav__botton">
            {i18n.navBotton.map((link, i) => (
              <div 
                onClick={() => window.location.href = link.path}
                key={`nav-bottom-${i}`}
                className="nav__botton__link" 
                dangerouslySetInnerHTML={{ __html: `${link.link} ${icons.dropdownIcons[i]}` }} 
              />
            ))}
          </div>
      }
    </>
  );
}

export default Header;
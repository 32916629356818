import React, { useState } from 'react'
import MovieTable from './MovieTable';

const arrowDownIcon = (
    <svg width="14" height="8" viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99999 5.17205L11.95 0.222046L13.364 1.63605L6.99999 8.00005L0.635986 1.63605L2.04999 0.222046L6.99999 5.17205Z" />
    </svg>
);

const CinemaList = ({ cinemaData, dropdown }) => {
    const [dropdowns, setDropdowns] = useState(cinemaData.map((cinema) => ({
        id: cinema.id,
        dropdown: dropdown ?? false,
    })));

    const handleChangeDropdowns = (id) => {
        setDropdowns(dropdowns.map(dropdown => (dropdown.id == id ? {
            id: dropdown.id,
            dropdown: !dropdown.dropdown,
        } : dropdown)))
    }

    const checkDropdown = (id) => (
        dropdowns.find(dropdown => (dropdown.id == id && dropdown.dropdown ? true : false))
    )

    return (
        <div className="cinemas-list">
            {
                cinemaData.map(cinema => (
                    cinema.movies.length > 0 &&
                        <div
                            className={`cinemas-list__cinema ${checkDropdown(cinema.id) ? '--active' : ''}`}
                            key={ cinema.id }
                        >
                            <div
                                className="cinemas-list__cinema__title"
                                onClick={ () => { handleChangeDropdowns(cinema.id) } }
                            >
                                <a href={cinema.url}>{ cinema.name }</a>
                                { arrowDownIcon }
                            </div>
                            {
                            checkDropdown(cinema.id) &&
                                <MovieTable movies={ cinema.movies } />
                            }
                        </div>
                ))
            }
        </div>
    )
}

export default CinemaList;

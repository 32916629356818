import React from 'react';

const MovieTableItem = ({ movie }) => {
    const { name, genres, classification, functions } = movie;
    
    return (
        <div className="cinemas-list__cinema__movies__movie">
            <div className="cinemas-list__cinema__movies__movie__information">
                <a
                    className="cinemas-list__cinema__movies__movie__information__title"
                    href={ movie.url }
                >
                    { name }
                </a>
                <p className="cinemas-list__cinema__movies__movie__information__classification-genre">
                    { genres.join(', ') } | { classification }
                </p>
            </div>
            <div className="cinemas-list__cinema__movies__movie__functions">
                {
                    functions.map((functionItem, index) => (
                        <div className="cinemas-list__cinema__movies__movie__functions__function" key={index}>
                            <p className="cinemas-list__cinema__movies__movie__functions__function__language">{ functionItem.language }</p>
                            <div className="cinemas-list__cinema__movies__movie__functions__function__hours">
                                {
                                functionItem.hours.map((hour, index) => (
                                <span key={ index }>{ hour }</span>))
                                }
                            </div>
                        </div>
                    ))
                }
                
            </div>
        </div>
    );
}

export default MovieTableItem;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Slide } from 'react-slideshow-image';

const CommerceSlides = ({ slides, arrowIcon }) => {
  const [lightBoxDisplay, setLightBoxDisplay] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  const properties = {
    defaultIndex: initialSlide,
    slidesToShow: 1,
    slidesToScroll: 1,
    indicators: true,
    autoplay: false,
    prevArrow: <button type="button" className="arrow-prev"><img src={arrowIcon} alt="Anterior" /></button>,
    nextArrow: <button type="button" className="arrow-next"><img src={arrowIcon} alt="Siguiente" /></button>,
  };

  const handleLightBox = e => {
    if (e.target.classList.contains('commerce-header__images__overlay')) {
      setLightBoxDisplay(false);
    };
  };

  return (
    <>
      {slides.slice(0, 5).map((image, index) => (
        <img
          src={image}
          className="commerce-header__images__image"
          alt=""
          key={image}
          onClick={() => {setLightBoxDisplay(true); setInitialSlide(index);}}
        />
      ))}

      <span
        className="commerce-header__images__count"
        onClick={() => {setLightBoxDisplay(true); setInitialSlide(index);}}
      >
        Ver {slides.length} fotos
      </span>

      {lightBoxDisplay && (
        <div
          className="commerce-header__images__overlay"
          onClick={(e) => handleLightBox(e)}
        >
          <Slide easing="ease" {...properties}>
            {slides.map(slide => (
              <img
                src={slide}
                alt=""
                key={`lightbox-${slide}`}
              />
            ))}
          </Slide>
        </div>
      )}
    </>
  )
};

CommerceSlides.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.string).isRequired,
  arrowIcon: PropTypes.string.isRequired,
};

export default CommerceSlides;

import React from 'react'



const BillboardCard = ({posterUrl, title, categories, i18nCard, iconsCard, path}) => {
  const ticketIcon = (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.25 13.75C7.25 13.4516 7.13147 13.1655 6.9205 12.9545C6.70952 12.7435 6.42337 12.625 6.125 12.625C5.82663 12.625 5.54048 12.7435 5.3295 12.9545C5.11853 13.1655 5 13.4516 5 13.75H1.25C1.05109 13.75 0.860322 13.671 0.71967 13.5303C0.579018 13.3897 0.5 13.1989 0.5 13V1C0.5 0.801088 0.579018 0.610322 0.71967 0.46967C0.860322 0.329018 1.05109 0.25 1.25 0.25H5C5 0.548369 5.11853 0.834517 5.3295 1.0455C5.54048 1.25647 5.82663 1.375 6.125 1.375C6.42337 1.375 6.70952 1.25647 6.9205 1.0455C7.13147 0.834517 7.25 0.548369 7.25 0.25H14.75C14.9489 0.25 15.1397 0.329018 15.2803 0.46967C15.421 0.610322 15.5 0.801088 15.5 1V5.125C15.0027 5.125 14.5258 5.32254 14.1742 5.67417C13.8225 6.02581 13.625 6.50272 13.625 7C13.625 7.49728 13.8225 7.97419 14.1742 8.32582C14.5258 8.67746 15.0027 8.875 15.5 8.875V13C15.5 13.1989 15.421 13.3897 15.2803 13.5303C15.1397 13.671 14.9489 13.75 14.75 13.75H7.25ZM6.125 5.875C6.42337 5.875 6.70952 5.75647 6.9205 5.5455C7.13147 5.33452 7.25 5.04837 7.25 4.75C7.25 4.45163 7.13147 4.16548 6.9205 3.9545C6.70952 3.74353 6.42337 3.625 6.125 3.625C5.82663 3.625 5.54048 3.74353 5.3295 3.9545C5.11853 4.16548 5 4.45163 5 4.75C5 5.04837 5.11853 5.33452 5.3295 5.5455C5.54048 5.75647 5.82663 5.875 6.125 5.875ZM6.125 10.375C6.42337 10.375 6.70952 10.2565 6.9205 10.0455C7.13147 9.83452 7.25 9.54837 7.25 9.25C7.25 8.95163 7.13147 8.66548 6.9205 8.4545C6.70952 8.24353 6.42337 8.125 6.125 8.125C5.82663 8.125 5.54048 8.24353 5.3295 8.4545C5.11853 8.66548 5 8.95163 5 9.25C5 9.54837 5.11853 9.83452 5.3295 10.0455C5.54048 10.2565 5.82663 10.375 6.125 10.375Z" fill="white"/>
    </svg>
  );

  return (
    <a href={path} className="billboard__cards__card">
      <div className="billboard__cards__card__image">
        <img src={posterUrl} alt={title} className="billboard__cards__card__image__img" />
        <img src={iconsCard.play} alt="" className="billboard__cards__card__image__play" />
        <div className="billboard__cards__card__ticket">
          <p>{i18nCard.payTicket}</p>
          {ticketIcon}
        </div>
      </div>
      <div className="billboard__cards__card__information">
        <h3 className="billboard__cards__card__information__title" >{title}</h3>
        {
          // <p className="billboard__cards__card__information__categories">{ categories.join(' / ') }</p>
        }
      </div>
      
    </a>
  )
}

export default BillboardCard

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchInput from '../components/SearchInput';
import CategoryCard from '../components/CategoryCard';

const Directory = ({ categoriesRails }) => {
    const [categoryInput, setCategoryInput] = useState('');
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        setCategories(categoriesRails);
    }, [])

    //Filter Categories
    useEffect(() => {
        const categoriesFiltered = categoriesRails.filter(categoryRails => categoryRails.name.toLowerCase().includes(categoryInput.toLowerCase()))
        setCategories(categoriesFiltered)
    }, [categoryInput])



    return (
        <>
            <div className="category-header">
                <h1 className="category-header__title">Directorio de negocios en <span>Morelos</span></h1>
                <SearchInput placeholder="Buscar categoría" setCategoryInput={ setCategoryInput }  />
                
            </div>

            <div className="category-body">
                {
                    categories.length > 0 ?
                        <div className="category-body__cards">
                            {
                                categories.map(category => (
                                    <CategoryCard
                                        key={ category.name }
                                        url={ category.url }
                                        name={ category.name }
                                        iconUrl={ category.iconUrl }
                                    />
                                ))
                            }
                        </div>
                        :
                        <h2 className="category-body__not-found">Categoría no encontrada</h2>
                }
            </div>
        </>
    )
}

Directory.propTypes = {

}

export default Directory;

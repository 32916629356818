import React from 'react';
import BillboardCard from './BillboardCard';

const BillboardMovies = ({genreSelected, i18nCard, iconsCard}) => {
  return (
    <div className="billboard__cards">
      {
        genreSelected.content.map(movie => <BillboardCard {...movie} i18nCard={i18nCard} iconsCard={iconsCard} key={movie.id} />)
      }
    </div>
  )
}

export default BillboardMovies;

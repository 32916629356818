import React from 'react';
import { useCinemaFilters } from '../hooks/useCinemaFilter';

const BillboardHeader = () => {
    const {
        urlSearch,
        selectOption,
    } = useCinemaFilters();

    const cineTypes = [
        {
            name: 'Cartelera',
            value: '',
        },{
            name: 'Cine de Arte',
            value: 'art-movies'
        },{
            name: 'Autocinemas',
            value: 'car-movies'
        }
    ];

    const handleSelectCineType = (value) => {
        selectOption({
            name: 'type',
            value: value
        });
    }

    return (
        <div class="billboard-navbar">
            {
                cineTypes.map(cineType => (
                    <div
                        class={`billboard-navbar__item ${urlSearch.type == cineType.value ? '--active' : ''}`}
                        key={ cineType.value }
                        onClick={ () => handleSelectCineType(cineType.value) }
                    >{ cineType.name }</div>
                ))
            }
        </div>
    );
}

BillboardHeader.propTypes = {

}

export default BillboardHeader;
import React from 'react';
import { useCinemaFilters } from '../hooks/useCinemaFilter';

const CinemaFilterByDay = ({
    currentDay,
    currentWeek
}) => {
    const {
        urlSearch,
        selectOption,
    } = useCinemaFilters();

    
    const handleSelectOption = (day) => {
        selectOption({
            name: 'day',
            value: day
        });
    }

    const currentMonth = new Date().toLocaleDateString('es-ES', { month: 'short' });

    return (
        <div className="cinema-header-week">
            <div class="month">
                    <p>{ currentMonth }</p>
                </div>
                {
                    currentWeek.map(item => (
                        <div
                            class={`day ${(urlSearch.day && urlSearch.day == item.day) || (!urlSearch.day && item.day == currentDay) ? '--active' : ''}`}
                            onClick={ () => handleSelectOption(item.day) }
                        >
                            <p>{ item.dayName }</p>
                            <p>{ item.day }</p>
                        </div>
                    ))
                }
        </div>
    );
}

export default CinemaFilterByDay;

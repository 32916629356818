import React, { useState, useEffect } from 'react'
import CommercePlanCard from './CommercePlanCard'
import { plans } from '../data/plans';

const CommercePlans = ( { urls, contracts, tooltips, authenticityToken, images, currentPlanID, buttonText, update, planType }) => {
    const [currentPlans, setCurrentPlans] = useState(plans.filter(item => item.type === (!planType ? 'Go' : 'Pro')));

    return (
        <div className="commerce-plan__body">
            <div className="commerce-plan__body__switch-area">
                <span
                    className="commerce-plan__body__switch-area__switch"
                >
                    <span className="--active">
                        {
                            !planType ?
                            'Planes GO'
                            :
                            'Planes PRO'
                        }
                    </span>
                </span>
            </div>
            <div className="commerce-plan__body__plans mb-4">
                <div className="commerce-plan__body__plans__cards">
                    {
                        currentPlans.map((currentPlan) => (
                            <CommercePlanCard
                                authenticityToken={ authenticityToken }
                                urls={ urls }
                                plan={ currentPlan } 
                                key={ currentPlan.id }
                                current={currentPlan.id == currentPlanID}
                                buttonText={buttonText} 
                                update={update}
                                images={images}
                                pdf={contracts[currentPlan.name]}
                                tooltips={tooltips[currentPlan.name]}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

CommercePlans.propTypes = {

}

export default CommercePlans

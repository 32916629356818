import { useState, useEffect } from 'react'

const useCinemaFilters = () => {
    const [urlSearch, setUrlSearch] = useState({
        type: '',
        genre: '',
        classification: '',
        language: '',
        search: '',
        day: ''
    });

    const [searchInput, setSearchInput] = useState('');
    const [inputSearchSubmited, setInputSearchSubmited] = useState(false);

    const [originalUrlParams, setOriginalUrlParams] = useState({
        type: '',
        genre: '',
        classification: '',
        language: '',
        search: '',
        day: ''
    });

    useEffect(() => {
        // Get url path on load page
        const newUrlSearch = window.location.search
        if (newUrlSearch) {
            const temporalUrlSearch = newUrlSearch.slice(1).split('&');
            temporalUrlSearch.forEach(item => {
                const itemArray = item.split('=')
                
                if (urlSearch.hasOwnProperty(itemArray[0])) {
                    setUrlSearch(urlSearch => ({
                        ...urlSearch,
                        [itemArray[0]]: itemArray[1]
                    }));
                    setOriginalUrlParams(urlSearch => ({
                        ...urlSearch,
                        [itemArray[0]]: itemArray[1]
                    }));
                    setSearchInput(urlSearch.search);
                }
            });
        }
        
    }, []);

    useEffect(() => {
        if (originalUrlParams) {
            if (
                originalUrlParams.type != urlSearch.type ||
                originalUrlParams.genre != urlSearch.genre ||
                originalUrlParams.classification != urlSearch.classification  ||
                originalUrlParams.language != urlSearch.language ||
                originalUrlParams.search != urlSearch.search ||
                originalUrlParams.day != urlSearch.day
            ) {
                const newUrlParams = `${window.location.origin}${window.location.pathname}?${generateUrlParams()}`;
                window.location.href = newUrlParams;
            }
        }
    }, [urlSearch]);

    useEffect(() => {
        if (inputSearchSubmited == true) {
            setUrlSearch({
                ...urlSearch,
                search: searchInput
            });
        }
    }, [inputSearchSubmited]);

    const generateUrlParams = () => {
        const newUrlParams = {}
        if (urlSearch.type) {
            newUrlParams['type'] = urlSearch.type;
        }
        if (urlSearch.genre) {
            newUrlParams['genre'] = urlSearch.genre;
        }
        if (urlSearch.classification) {
            newUrlParams['classification'] = urlSearch.classification;
        }
        if (urlSearch.language) {
            newUrlParams['language'] = urlSearch.language;
        }
        if (urlSearch.search) {
            newUrlParams['search'] = urlSearch.search;
        }
        if (urlSearch.day) {
            newUrlParams['day'] = urlSearch.day;
        }

        const urlParameters = Object.entries(newUrlParams).map(e => e.join('=')).join('&');
        return urlParameters
    }

    const searchEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            setUrlSearch({
                ...urlSearch,
                search: searchInput
            });
            setInputSearchSubmited(true);
        }
    }

    const selectOption = (target) => {
        if (urlSearch.hasOwnProperty(target.name)) {
            setUrlSearch(urlSearch => ({
                ...urlSearch,
                [target.name]: target.value
            }));
        }
    }

    const cleanOption = (name) => {
        setUrlSearch(urlSearch => ({
            ...urlSearch,
            [name]: ''
        }));
    }

    return {
        urlSearch,
        searchEnter,
        searchInput,
        selectOption,
        cleanOption,
        setSearchInput
    };
}

export { useCinemaFilters }

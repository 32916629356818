import { useState, useEffect } from 'react'

const initialList = ( originalList ) => {
    if (originalList.length === 2) {
      return [
        ...originalList,
        ...originalList
      ];
    }
    return originalList;
}

const configureActiveList = ( list, slidesToShow ) => {
    if(list.length === 4) {
        return list.slice(0, 3);
    } 
    return list.slice(0, slidesToShow);
}



const initialActiveItem = ( activeList ) => {
    return activeList[Math.round(activeList.length / 2) - 1];
}

const useSlideshow = ({
    originalList,
    slidesToShow,
    slidesToScroll
}) => {
    const getSlidesToShow = ( ) => {
        if (list.length === 4) {
            return 3
        }
        if (list.length >= slidesToShow) {
            return slidesToShow
        }
        if (list.length % 2) {
            return list.length
        } else {
            return list.length - 1;
        }
    }
    const [list, setList] = useState(initialList(originalList));
    const [activeList, setActiveList] = useState(configureActiveList(list, getSlidesToShow()));
    const [activeItem, setActiveItem] = useState(initialActiveItem(activeList));

    const goNext = () => {
        const toChangePosition = list.slice(0,slidesToScroll);
        const remainingList = list.slice(slidesToScroll);
        const newList = [
            ...remainingList,
            ...toChangePosition
        ]
        setList(newList);
    }

    const goBack = () => {
        const toChangePosition = list.slice(-slidesToScroll);
        const remainingList = list.slice(0, -slidesToScroll);
        const newList = [
            ...toChangePosition,
            ...remainingList,
        ]
        console.log(newList)
        setList(newList);
    }

    const moveList = (item) => {
        const index = list.map((e) => ( e.title )).indexOf(item.title);
        const activeIndex = list.map((e) => ( e.title )).indexOf(activeItem.title);
        const newList = [
            ...list.slice(index),
            ...list.slice(0, index),
        ];

        const newListImprove = [
            ...newList.slice(-parseInt(getSlidesToShow() / 2)),
            ...newList.slice(0, -parseInt(getSlidesToShow() / 2))
        ]

        setList(newListImprove);
    }

    useEffect(() => {
        setActiveList(configureActiveList(list, getSlidesToShow()));
    }, [ list, slidesToShow ]);
      
    useEffect(() => {
        setActiveItem(activeList[Math.round(activeList.length / 2) - 1]);
    }, [ activeList ]);

    return {
        activeList,
        activeItem,
        goNext,
        goBack,
        moveList,
    }
}

export { useSlideshow }
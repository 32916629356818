import React from 'react';
import PropTypes from 'prop-types';

const BillboardOption = ({genre, genres, changeGenreSelected}) => {

  const handleSelect = (option) => {
    changeGenreSelected(option);
  }

  return (
    <div className="billboard__options">
      <ul className="billboard__options__categories">
        {
          genres.map(option => (
            <li
              key={option}
              onClick={() => handleSelect(option)}
              className={`billboard__options__categories__category ${option == genre ? '--active' : ''}`}
            >{option}</li>
          ))
        }
      </ul>
      <div className="billboard__options__pagination">
        <div className="billboard__options__pagination__before"></div>
        <div className="billboard__options__pagination__next"></div>
      </div>
    </div>
  )
}

BillboardOption.propTypes = {
  category: PropTypes.string.isRequired,
  setCategory: PropTypes.func.isRequired,
}

export default BillboardOption;

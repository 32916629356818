import React, { useState } from 'react';
import PropTypes from 'prop-types';

const playIcon = (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8">
      <g clip-path="url(#clip0_48_275)">
        <rect width="48" height="48" rx="24" fill="white" />
        <path d="M23.9999 46.0252C11.8353 46.0252 1.97461 36.1645 1.97461 23.9999C1.97461 11.8353 11.8353 1.97461 23.9999 1.97461C36.1645 1.97461 46.0252 11.8353 46.0252 23.9999C46.0252 36.1645 36.1645 46.0252 23.9999 46.0252ZM20.9648 16.1038C20.8323 16.0154 20.6782 15.9646 20.5191 15.9568C20.3599 15.949 20.2016 15.9845 20.061 16.0595C19.9204 16.1345 19.8028 16.2463 19.7208 16.3829C19.6387 16.5194 19.5952 16.6757 19.5949 16.8351V31.1648C19.5952 31.3241 19.6387 31.4804 19.7208 31.617C19.8028 31.7536 19.9204 31.8653 20.061 31.9404C20.2016 32.0154 20.3599 32.0509 20.5191 32.0431C20.6782 32.0353 20.8323 31.9844 20.9648 31.896L31.711 24.7334C31.8318 24.6529 31.9309 24.5439 31.9995 24.416C32.068 24.288 32.1039 24.1451 32.1039 23.9999C32.1039 23.8548 32.068 23.7119 31.9995 23.5839C31.9309 23.4559 31.8318 23.3469 31.711 23.2665L20.9626 16.1038H20.9648Z" fill="#FD495C" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_48_275">
        <rect width="48" height="48" rx="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const MovieTrailer = ({ url, externalUrl }) => {

  const [showModal, setShowModal] = useState(false);

  const handleTrailerDisplay = e => {
    if (e.target.classList.contains('billboard-header__movie__trailer')) {
      setShowModal(false);
    };
  };
  
  return (
    externalUrl ? 
    (url && url !== '' &&
      <a href={url} target="_blank" title="Ver tráiler" className="billboard-header__movie__poster__play-btn">
        {playIcon}
      </a>
    )
    :
    (
      <>
        <button type="button" title="Ver tráiler" onClick={() => setShowModal(true)}>{playIcon}</button>

        {showModal && (
          <div className="billboard-header__movie__trailer" onClick={(e) => handleTrailerDisplay(e)}>
            <video src={url} controls />
          </div>
        )}
      </>
    )
  )
};

export default MovieTrailer;

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import BillboardCard from './BillboardCard';

const PrevArrow = () => <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="17" transform="rotate(-180 17 17)" fill="#FCFCFC"/>
<path d="M15.3391 17L22.3516 24.0125L20.3484 26.0156L11.3327 17L20.3484 7.98429L22.3516 9.98745L15.3391 17Z" />
</svg>;

const NextArrow = () => <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="17" cy="17" r="17" fill="#FCFCFC"/>
  <path d="M18.6609 17L11.6484 9.98754L13.6516 7.98438L22.6673 17L13.6516 26.0157L11.6484 24.0125L18.6609 17Z" />
</svg>;

const BillboardSimpleCardList = ({ billboards, i18n, icons, path }) => {
    const cards = useRef();      
    
    const handlePagination = (direction) => {
        const { scrollLeft, scrollWidth, offsetWidth } = cards.current;
    
        cards.current.scrollTo({
          top: 0,
          left: scrollLeft + direction,
          behavior: 'smooth',
        });
    };

    console.log(i18n)


    return (
        <>
            <div className="billboard__header">
                <h2 className="billboard__header__title">{i18n.header.title}</h2>
                <a className="billboard__header__button" href={ path } >{i18n.header.button}</a>
            </div>
            <div className="event__options__pagination">
                <button type="button" className="event__options__pagination__before" onClick={() => handlePagination(-300)}>
                    <PrevArrow/>
                </button>
                <button type="button" className="event__options__pagination__next" onClick={() => handlePagination(280)}>
                    <NextArrow/>
                </button>
            </div>
            <div className="billboard__cards" ref={cards}>
                {
                    billboards &&
                        billboards.map(movie => <BillboardCard {...movie} i18nCard={i18n.card} iconsCard={icons} key={movie.id} />)
                }
            </div>
        </>
    )
}

BillboardSimpleCardList.propTypes = {

}

export default BillboardSimpleCardList

import React, { useState } from 'react'
import BillboardCards from './BillboardCards';
import BillboardOption from './BillboardOption';

const Billboard = ({i18n, movies, icons, path}) => {
  
  /* Billboards filter */
  const [genre, setGenre] = useState(movies[0]);

  const genres = movies.filter(movie => {
    if (movie.content.length > 0) {
      return movie.name
    }
  })
  
  const genresNames = genres.map(item => item.name);

  const { name:genreName } = genre;

  const changeGenreSelected = (selectedName) => {
    setGenre(genres.find(item => item.name === selectedName));
  }

  return (
    <section className="billboard" id="cine">
      <div className="billboard__header">
        <h2 className="billboard__header__title">{i18n.header.title}</h2>
        <a className="billboard__header__button" href={path} >{i18n.header.button}</a>
      </div>

      <BillboardOption
        genre={genreName}
        setGenre={setGenre}
        genres={ genresNames }
        changeGenreSelected={ changeGenreSelected }
      />
      <BillboardCards genreSelected={genre} i18nCard={i18n.card} iconsCard={icons} />
    </section>
  )
}

export default Billboard;
import React, { useState } from 'react';
import PlanTooltip from './PlanTooltip';

const CommercePlanCard = ({ urls, plan, authenticityToken, tooltips, current, buttonText, update, images, pdf }) => {
    const { id, name, type, price, description, features, featuresInfo, featuresDisabled, promotions, payMethods } = plan;
    const [updatePlan, setUpdatePlan] = useState(false);
    
    return (
        <div className={`plan-card ${(type === 'Pro') ? '--dark' : ''} ${current ? '--disabled' : ''}`}>
            <div>
                <div>
                <ul className="plan-card__promotions">
                    { promotions.map((promotion, index) => (
                        <li key={index}><span>{ promotion }</span></li>
                    )) }
                </ul> 
                <p className="plan-card__pay-methods">{ payMethods }</p>
            </div>
            <div className='plan-card__image'>
                <img src={(type === 'Pro') ? images.cardPro : images.cardGo} alt="" srcset={(type === 'Pro') ? images.cardPro : images.cardGo} />
            </div>
            <div>
                <h3 className="plan-card__title">{ `Sitio ${type} ${name}` }</h3>
                <div className="plan-card__price"><p className="price">${price}</p><p className="currency">mx / mes</p></div>
                <div className="plan-card__horizontal-rule"></div>
                <div className="plan-card__description">{ description }</div>
                <div className="plan-card__horizontal-rule"></div>
                <ul className="plan-card__features">
                    { featuresInfo.map((feature, index) => (
                        <li title={`Sitio ${type} ${name}`} className="--info" key={ index }>
                            { feature }
                            {index < (type === 'Go' ? 2 : 3) && (
                                <PlanTooltip image={tooltips.banner} />
                            )}
                            {index === (type === 'Go' ? 2 : 3) && (
                                <PlanTooltip image={tooltips.buttons} />
                            )}
                        </li>
                    )) }
                    { features.map((feature, index) => (
                        <li  key={index}>{ feature }</li>
                    )) }
                    { featuresDisabled.map((feature, index) => (
                        <li className="--disabled" key={ index }>{ feature }</li>
                    )) }
                </ul>
            </div>
            </div>
            <div>
                
                {
                    !urls.redirectPage ?
                    <>
                        {!update && 
                        (<form method="post" action={ urls.selectPlan } className="inline">
                            <input type="hidden" name="id" value={ id } />
                            <input
                                type="hidden"
                                name="authenticity_token"
                                value={ authenticityToken }
                            />
                            <button type="submit" className="plan-card__button" disabled={current}>{current ? "Plan actual" : buttonText}</button>
                        </form>)}
        
                        {update && 
                        (<>
                            <button
                                type="button"
                                className="plan-card__button"
                                disabled={current}
                                onClick={() => setUpdatePlan(true)}
                            >
                                {current ? "Plan actual" : buttonText}
                            </button>
        
                            {updatePlan && (
                                <div className="plan-card__popup">
                                    <form method="post" action={ urls.selectPlan } className="inline">
                                        <p>
                                            Al cambiar de plan, tus privilegios se actualizarán con la próxima facturación.
                                            <br/>
                                            <span>¿Deseas continuar?</span>
                                        </p>
                                        <input type="hidden" name="id" value={ id } />
                                        <input
                                            type="hidden"
                                            name="authenticity_token"
                                            value={ authenticityToken }
                                        />
                                        <div>
                                            <button
                                                type="button"
                                                className="plan-card__popup__button"
                                                onClick={() => setUpdatePlan(false)}
                                            >
                                                Cancelar
                                            </button>
                                            <button type="submit" className="plan-card__popup__button">Continuar</button>
                                        </div>
                                    </form>
                                </div>
                            )}  
                        </>)}
                    </>
                    :
                    <a
                        type="button"
                        className="plan-card__button text-center"
                        href={ urls.redirectPage }
                    >
                        {buttonText}
                    </a>
                }

                <a href={pdf} target="_blank" className="plan-card__terms-license">Se aplican términos y condiciones de contratación. Precios incluyen IVA y en M.N.</a>
            </div>

            
        </div>
    )
}

CommercePlanCard.propTypes = {

}

export default CommercePlanCard

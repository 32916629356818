import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DropDown = ({title, routes, icons}) => {
    const [isOpen, setIsOpen] = useState(false);
    
    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    
    return (
        <div className="footer__container__dropdown__container">
            <div onClick={handleClick} className="footer__container__dropdown__container__header">
                <span>{title}</span>
                <div dangerouslySetInnerHTML={{ __html: isOpen ? icons.up_icon : icons.down_icon  }}>
                </div>
            </div>
            {isOpen && (
                <div className="footer__container__dropdown__container__content">
                {
                    routes.map((route, index) => (
                        <a key={index+route.path} href={route.path}>{route.name}</a>
                    ))
                }
                </div>
            )}
        </div>
    );
};

const FooterDropdown = ({icons}) => {
    const hubsLinks = [
        {name: 'Facebook', path: 'https://www.facebook.com/grupoYOVI/'},
        {name: 'Instagram', path: 'https://www.instagram.com/grupoyovi/'},
    ];
    const quickyLinks = [
        {name: 'Que hacer', path: '/'},
        {name: 'Directorio', path: '/directorio'},
        {name: 'Eventos', path: '/eventos'},
        // {name: 'Cine', path: '/cartelera'},
    ];
    const businessLinks = [
        {name: 'Suscribe tu negocio', path: 'https://panel.yovimorelos.com/comercio/sign_in'},
    ];
    return (
        <>
            <DropDown title="Redes Sociales" routes={hubsLinks} icons={icons}/>
            <DropDown title="Links Rapidos" routes={quickyLinks} icons={icons}/>
            <DropDown title="Negocios" routes={businessLinks} icons={icons} />
        </>
    );
}

DropDown.propTypes = {
    title: PropTypes.string.isRequired,
    routes: PropTypes.array,
}

export default FooterDropdown;
import React from 'react'
import Swipe from 'react-easy-swipe';
import { useSlideshow } from '../hooks/useSlideshow'


const MovieSlideshow = ({ movies }) => {
    const {
        activeList,
        activeItem,
        goNext,
        goBack,
        moveList,
    } = useSlideshow({
        originalList: movies,
        slidesToShow: 7,
        slidesToScroll: 1,
    });

    const handleMoveList = (e, movie) => {
        if (movie.title !== activeItem.title) {
            e.preventDefault();
        }

        moveList(movie)
    }

    const onSwipeLeft = () => {
        goNext();
    }

    const onSwipeRight = () => {
        goBack();
    }

    return (
        <Swipe
            onSwipeLeft={onSwipeLeft}
            onSwipeRight={onSwipeRight}
        >
            <div className="billboard-header__slideshow">
                {
                    activeList.map(movie => (
                        <>  
                            {
                                movie.title == activeItem.title &&
                                    <div className="billboard-header__slideshow__button" onClick={ goBack }>
                                        <svg width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.65664 12.9986L15.5566 22.8986L12.7286 25.7266L0.000640075 12.9986L12.7286 0.270561L15.5566 3.09856L5.65664 12.9986Z" fill="#FD495C"/>
                                        </svg>
                                    </div>
                            }
                            <a
                                className={`movie-card ${movie.title == activeItem.title ? '--active' : ''}`}
                                key={ movie.title }
                                href={ movie.path }
                                onClick={(e) => {handleMoveList(e, movie)}}
                            >
                                <div className="movie-card__image">
                                    <img src={movie.img} alt="" />
                                </div>
                                <div className="movie-card__information">
                                    <h3 className="movie-card__information__title">{ movie.title }</h3>
                                    { 
                                        //<p className="movie-card__information__language">{ movie.language }</p> 
                                    }
                                    <p className="movie-card__information__genre-classification">{ movie.genre } | { movie.classification }</p>
                                </div>
                            </a>
                            {
                                movie.title == activeItem.title &&
                                    <div className="billboard-header__slideshow__button" onClick={ goNext }>
                                        <svg width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.3434 13.0014L0.443359 3.10144L3.27136 0.273438L15.9994 13.0014L3.27136 25.7294L0.443359 22.9014L10.3434 13.0014Z" fill="#FD495C"/>
                                        </svg>
                                    </div>
                            }
                        </>
                    ))
                }
            </div>
        </Swipe>
    )
}

MovieSlideshow.propTypes = {

}

export default MovieSlideshow

import React from "react";

const infinityIcon = (<svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.5141 0.171875C15.1092 0.171875 12.8559 2.95574 10.868 5.4124C9.16957 7.51104 7.56571 9.49274 5.6123 9.49274C3.58187 9.49274 2.33525 7.40592 2.33525 5.9099C2.33525 4.52285 3.70281 2.50712 5.84619 2.50712C7.92598 2.50712 9.00949 4.17169 9.06412 4.257C9.40106 4.80227 10.1165 4.97386 10.6645 4.63963C11.2152 4.30413 11.3896 3.58568 11.054 3.03498C10.9827 2.91835 9.2699 0.172035 5.84619 0.172035C2.40107 0.172035 0 3.19634 0 5.91006C0 8.70926 2.30489 11.8281 5.61214 11.8281C8.67942 11.8281 10.7149 9.31348 12.6831 6.88142C14.5037 4.63228 16.2236 2.50728 18.5141 2.50728C19.8768 2.50728 20.7723 2.96964 21.251 3.91958C21.6657 4.74396 21.6654 5.67681 21.6651 6.00001C21.6651 6.18533 21.5671 7.11483 21.1137 7.95183C20.5967 8.90529 19.8628 9.34911 18.8021 9.34911C16.6663 9.34911 15.5123 7.96077 15.0777 7.43787C14.9648 7.30175 14.8835 7.20382 14.7748 7.11084C14.2856 6.69178 13.5482 6.74802 13.1283 7.23785C12.7235 7.71027 12.7625 8.41258 13.2047 8.83771C13.2258 8.86167 13.2512 8.89315 13.2814 8.92973C13.8477 9.61128 15.5698 11.684 18.8016 11.684C22.7296 11.684 23.9998 7.85405 23.9998 5.98403C24.0002 4.793 23.7771 3.74528 23.3362 2.8693C22.7171 1.63913 21.3777 0.171875 18.5141 0.171875Z" fill="#FD495C"/>
</svg>);

const lockerIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 10H20C20.2652 10 20.5196 10.1054 20.7071 10.2929C20.8946 10.4804 21 10.7348 21 11V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V11C3 10.7348 3.10536 10.4804 3.29289 10.2929C3.48043 10.1054 3.73478 10 4 10H5V9C5 8.08075 5.18106 7.1705 5.53284 6.32122C5.88463 5.47194 6.40024 4.70026 7.05025 4.05025C7.70026 3.40024 8.47194 2.88463 9.32122 2.53284C10.1705 2.18106 11.0807 2 12 2C12.9193 2 13.8295 2.18106 14.6788 2.53284C15.5281 2.88463 16.2997 3.40024 16.9497 4.05025C17.5998 4.70026 18.1154 5.47194 18.4672 6.32122C18.8189 7.1705 19 8.08075 19 9V10ZM17 10V9C17 7.67392 16.4732 6.40215 15.5355 5.46447C14.5979 4.52678 13.3261 4 12 4C10.6739 4 9.40215 4.52678 8.46447 5.46447C7.52678 6.40215 7 7.67392 7 9V10H17ZM11 14V18H13V14H11Z" fill="#C8C8C8"/>
</svg>);

const checkIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9994 21L0 9.23213L6.35473 3L11.9994 8.53574L17.644 3L24 9.23213L11.9994 21Z" fill="#FD495C"/>
    </svg>);

const plans = [
    {
        id: 'plan_go_basic',
        type: 'Go',
        name: 'Basic',
        price: '99',
        description: (
            <p className='commerce-plan__description'>
                <strong>Micro negocios</strong> que quieren tener <span>presencia básica</span> y <span>efectiva</span> en Internet para que puedan darse a conocer, <strong>recibir llamadas</strong> y <strong>visitas</strong> a su sucursal.
            </p>
        ),
        features: [
            (<p>
                {checkIcon}
                <div>
                    <strong>1</strong> Sucursal
                </div>
            </p>)
        ],
        featuresInfo: [
            (<p>
                {checkIcon}
                <div>
                    Banner <strong>MEDIANO</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>4ta</strong> posición de <strong>BLOQUE</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>2</strong> Botones de acción
                </div>
            </p>),
        ],
        featuresDisabled: [
            (<p>
                {lockerIcon}
                <div>
                    Promociones
                </div>
            </p>),
            (<p>
                {lockerIcon}
                <div>
                    Eventos
                </div>
            </p>)
        ],
        promotions: [
            '+2 meses gratis al suscribirte'
        ],
        payMethods: 'Disponible solo tarjeta crédito o débito',
    },
    {
        id: 'plan_go_plus',
        type: 'Go',
        name: 'Plus',
        price: '299',
        description: (
            <p className='commerce-plan__description'>
                <strong>Negocios Pyme</strong> que quieren ser <span>contactadas</span> de forma muy fácil y rápida mediante cualquier vía de comunicación como llamadas, visitas a sucursal y <span>WhatsApp.</span>
            </p>
        ),
        features: [
            (<p>
                {checkIcon}
                <div>
                    <strong>3</strong> Sucursales
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span><strong>3</strong> Promociones</span>
                </div>
            </p>)
        ],
        featuresInfo: [
            (<p>
                {checkIcon}
                <div>
                    Banner <strong>MEDIANO</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>4ta</strong> posición de <strong>BLOQUE</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>4</strong> Botones de acción
                </div>
            </p>),
            
        ],
        featuresDisabled: [
            (<p>
                {lockerIcon}
                <div>
                    Eventos
                </div>
            </p>)
        ],
        promotions: [
            '+2 meses gratis al suscribirte'
        ],
        payMethods: 'Disponible solo tarjeta crédito o débito',
    },
    {
        id: 'plan_go_max',
        type: 'Go',
        name: 'Max',
        price: '990',
        description: (
            <p className='commerce-plan__description'>
                <strong>Negocios medianos</strong> que quieren difundir sus <span>campañas y promos</span> todo el tiempo para que sus clientes puedan aprovecharlas al máximo.
            </p>
        ),
        features: [
            (<p>
                {checkIcon}
                <div>
                    <strong>5</strong> Sucursales
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    { infinityIcon }<span style={{marginLeft: 10}}>Promociones</span>
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    { infinityIcon }<span style={{marginLeft: 10}}>Eventos</span>
                </div>
            </p>)
        ],
        featuresInfo: [
            (<p>
                {checkIcon}
                <div>
                    Banner <strong>MEDIANO</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>4ta</strong> posición de <strong>BLOQUE</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>6</strong> Botones de acción
                </div>
            </p>),
            
        ],
        featuresDisabled: [],
        promotions: [
            '+2 meses gratis al suscribirte'
        ],
        payMethods: 'Disponible solo tarjeta crédito o débito',
    },
    {
        id: 'plan_pro_bronce',
        type: 'Pro',
        name: 'Bronce',
        price: '2,490',
        description: (
            <p className='commerce-plan__description'>
                <strong>Grandes empresas y corporativos</strong> que buscan ser reconocidos como los <span>negocios más destacados</span> de toda su localidad.
            </p>
        ),
        featuresInfo: [
            (<p>
                {checkIcon}
                <div>
                    Banner <strong>MEDIANO</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>4ta</strong> posición de <strong>BLOQUE</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>Destacado</strong> en lista de negocios
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Botones de acción</span>
                </div>
            </p>),
        ],
        features: [
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Sucursales</span>
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Promociones ilimitadas</span>
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Eventos</span>
                </div>
            </p>),
        ],
        featuresDisabled: [
            (<p>
                {checkIcon}
                <div>
                    <span>Tienda en línea*</span>
                </div>
            </p>)
        ],
        promotions: [
            '+2 meses gratis al suscribirte',
        ],
        payMethods: 'Disponible cualquier método de pago',
    },
    {
        id: 'plan_pro_plata',
        type: 'Pro',
        name: 'Plata',
        price: '4,990',
        description: (
            <p className='commerce-plan__description'>
                <strong>Grandes empresas y corporativos</strong> que buscan ser reconocidos como los <span>negocios más destacados</span> de toda su localidad.
            </p>
        ),
        featuresInfo: [
            (<p>
                {checkIcon}
                <div>
                    Banner <strong>MEDIANO</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>3ra</strong> posición de <strong>BLOQUE</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>Top</strong> en lista de negocios
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Botones de acción</span>
                </div>
            </p>),
        ],
        features: [
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Sucursales</span>
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Promociones</span>
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Eventos</span>
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>Tienda en línea*</span>
                </div>
            </p>)
        ],
        featuresDisabled: [],
        promotions: [
            '+2 meses gratis al suscribirte',
        ],
        payMethods: 'Disponible cualquier método de pago',
    },
    {
        id: 'plan_pro_oro',
        type: 'Pro',
        name: 'Oro',
        price: '9,990',
        description: (
            <p className='commerce-plan__description'>
                <strong>Grandes empresas y corporativos</strong> que buscan ser reconocidos como los <span>negocios más destacados</span> de toda su localidad.
            </p>
        ),
        featuresInfo: [
            (<p>
                {checkIcon}
                <div>
                    Banner <strong>GRANDE</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>2da</strong> posición de <strong>BLOQUE</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>Top</strong> en lista de negocios
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Botones de acción</span>
                </div>
            </p>),
        ],
        features: [
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Sucursales</span>
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Promociones</span>
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Eventos</span>
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>Tienda en línea*</span>
                </div>
            </p>)
        ],
        featuresDisabled: [],
        promotions: [
            '+2 meses gratis al suscribirte',
        ],
        payMethods: 'Disponible cualquier método de pago',
    },
    {
        id: 'plan_pro_platino',
        type: 'Pro',
        name: 'Platino',
        price: '14,990',
        description: (
            <p className='commerce-plan__description'>
                <strong>Grandes empresas y corporativos</strong> que buscan ser reconocidos como los <span>negocios más destacados</span> de toda su localidad.
            </p>
        ),
        featuresInfo: [
            (<p>
                {checkIcon}
                <div>
                    Banner <strong>MEGA PLUS</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>1ra</strong> posición de <strong>BLOQUE</strong> dentro del directorio
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <strong>Top</strong> en lista de negocios
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Botones de acción</span>
                </div>
            </p>),
        ],
        features: [
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Sucursales</span>
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Promociones</span>
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>{ infinityIcon } Eventos</span>
                </div>
            </p>),
            (<p>
                {checkIcon}
                <div>
                    <span>Tienda en línea*</span>
                </div>
            </p>)
        ],
        featuresDisabled: [],
        promotions: [
            '+2 meses gratis al suscribirte',
        ],
        payMethods: 'Disponible cualquier método de pago',
    },
];

export { plans };

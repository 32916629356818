import React from 'react'
import MovieTableItem from './MovieTableItem';



const MovieTable = ({ movies }) => {
    return (
        <div className="cinemas-list__cinema__movies">
            { movies.map(movie => (
                <MovieTableItem movie={movie} key={movie.id} />
            )) }
        </div>
    )
}

export default MovieTable;
